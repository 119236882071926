import { React } from "react";
import "./page-content.css";
import { Layout, Image, Row, Col } from "antd";
import IntroCard from "./IntroCard/IntroCard";
import ServicesCard from "./ServicesCard/ServicesCard";
import ClientListCard from "./ClientListCard/ClientListCard";
import AboutUsLargeCard from "./AboutUsLargeCard/AboutUsLargeCard";
import DIRCard from "./DIRCard/DIRCard";
import MaintenanceCard from "./MaintenanceCard/MaintenanceCard";

// const banner = require("../assets/capitol-consulting-logo-drop-shadow.png");
const banner = require("../assets/banner-wide-condensed.png");

const { Content } = Layout;
let mobileUser = false;

const PageContent = (props) => {
  const vw = document.documentElement.clientWidth;
  if (vw < 481) {
    mobileUser = true;
  }

  // maintenace page while the site is recreated.
  return MaintenanceCard();

  // 480px is xs
  // 576px is sm
  // 768px is md

  if (props.tab === "about-us-contact") {
    return AboutUsLargeCard();
  } else if (props.tab === "dir-hub-info") {
    return DIRCard();
  } else if (props.tab === "services") {
    return ServicesCard();
  } else if (props.tab === "client-list") {
    return ClientListCard();
  } else if (mobileUser) {
    return (
      <Content
        style={{
          padding: "0 5%",
          opacity: "97%",
        }}
      >
        <Row>
          <Col span={24}></Col>
        </Row>
        <Row>
          <Col span={0} lg={4} xl={4} xxl={4}></Col>
          <Col xs={0} md={16} lg={16} xl={16} xxl={16}>
            <Image
              style={{
                marginLeft: "1%",
                marginBottom: "2%",
                marginTop: "1.5%",
              }}
              src={banner}
              preview={false}
            />
          </Col>
          <Col span={0} xs={24}>
            <br></br>
          </Col>
          <Col
            xs={24}
            md={0}
            style={{
              backgroundColor: "white",
              marginBottom: "10%",
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            <p style={{ marginBottom: "0px" }}>
              Welcome to Capitol Consulting Services!
            </p>
            <p style={{ marginBottom: "0px", fontSize: "14px" }}>
              Tap the top left icon to navigate the site.
            </p>
          </Col>
          <Col span={24} style={{ marginTop: "1.5%" }}>
            {" "}
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ height: "100%", textAlign: "center" }}>
            <div className="site-layout-content">{IntroCard()}</div>
          </Col>
          <Col span={0} xs={24}>
            <br></br>
          </Col>
        </Row>
      </Content>
    );
  } else {
    return (
      <Content
        style={{
          padding: "0 5%",
          opacity: "97%",
        }}
      >
        <Row>
          <Col span={24}></Col>
        </Row>
        <Row>
          <Col span={0} lg={4} xl={4} xxl={4}></Col>
          <Col xs={0} md={16} lg={16} xl={16} xxl={16}>
            <Image
              style={{
                marginLeft: "1%",
                marginBottom: "2%",
                marginTop: "1.5%",
              }}
              src={banner}
              preview={false}
            />
          </Col>
          <Col span={0} xs={24}>
            <br></br>
          </Col>
          <Col
            xs={24}
            md={0}
            style={{
              backgroundColor: "white",
              marginBottom: "10%",
              textAlign: "center",
              fontSize: "18px",
              borderRadius: "5px",
              border: "1px solid maroon",
            }}
          >
            <p style={{ marginBottom: "0px" }}>
              Welcome to Capitol Consulting Services!
            </p>
            <p style={{ marginBottom: "0px", fontSize: "14px" }}>
              Tap the top left icon to navigate the site.
            </p>
          </Col>
          <Col span={24} style={{ marginTop: "1.5%" }}>
            {" "}
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{ height: "100%", textAlign: "center", marginTop: "-1%" }}
          >
            <div className="site-layout-content">{IntroCard()}</div>
          </Col>
          <Col span={0} xs={24}>
            <br></br>
          </Col>
        </Row>
        <Row gutter={32} style={{ marginTop: "2%" }}>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{ height: "100%" }}
          >
            {ClientListCard()}
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{ height: "100%" }}
          >
            {ServicesCard()}
          </Col>
        </Row>
      </Content>
    );
  }
};

export default PageContent;
