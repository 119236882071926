import { React } from "react";
import { Col, Row, Layout, Image } from "antd";
import "./MaintenanceCard.css";
const banner = require("../../assets/capitol-consulting-logo-drop-shadow.png");

const { Content } = Layout;

const vw = document.documentElement.clientWidth;
let mobileUser = false;
if (vw < 481) {
  mobileUser = true;
}

let MaintenanceCardClass = mobileUser
  ? "site-layout-content-dir-card-mobile"
  : "site-layout-content-dir-card";

const MaintenanceCard = () => {
  return (
    <div>
      <Content
        style={{
          padding: "0 5%",
          opacity: "97%",
        }}
      >
        <Row>
          <Col span={24}></Col>
        </Row>
        <Row>
          <Col span={0} lg={4} xl={4} xxl={4}></Col>
          <Col xs={0} lg={16} xl={16} xxl={16}>
            <Image
              style={{
                marginLeft: "1%",
                marginBottom: "2%",
                marginTop: "1.5%",
              }}
              src={banner}
              preview={false}
            />
          </Col>
          <Col span={24} style={{ marginTop: "1.5%" }}>
            {" "}
          </Col>
        </Row>
        <div className="fadeIn2">
          <div className={MaintenanceCardClass}>
            <Row style={{ width: "100%" }}>
              <Col span={24}>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "2em",
                    marginTop: "2%",
                  }}
                >
                  Site Down for Maintenance
                </h1>
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "0%",
                    paddingBottom: "5%",
                    paddingLeft: "0%",
                  }}
                >
                  This site is being remodeled and will return soon! Please
                  check back later.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default MaintenanceCard;
